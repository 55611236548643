.c-chart-line {
	fill: none;
	stroke: theme('colors.emphasis.700');
	stroke-width: 2px;
	stroke-linejoin: round;
}

.c-chart-line--subtle {
	stroke-opacity: 0.25;
}
