.c-search {
	@apply relative;
	flex: 1 1 100%;
}

.c-search__image-container {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply bottom-0;
	@apply p-3;
	@apply flex;
	@apply items-center;
	@apply justify-center;
	pointer-events: none;
}

.c-search__icon {
	height: 1rem;
	width: 1rem;
	fill-opacity: 0.5;
}

.c-search__input {
	@apply block;
	@apply pl-10;
	@apply w-full;
	@apply leading-none;
}

.c-search__suggestions {
	@apply absolute;
	@apply bottom-0;
	@apply left-0;
	@apply right-0;
	@apply bg-white;
	@apply shadow-lg;
	@apply text-grey-600;
	transform: translateY(100%);
}

.c-search__suggestion {
	@apply relative;
	@apply p-3;
	@apply pl-10;
	cursor: pointer;
}

.c-search__suggestion:nth-child(even) {
	@apply bg-grey-200;
}

.c-search__suggestion.is-highlighted {
	@apply bg-emphasis-700;
	@apply text-emphasis-100;
}

.c-search__suggestion.is-selected:not(.is-highlighted) {
	@apply text-grey-900;
}

.c-search__suggestion-image {
	@apply rounded-full;
	width: 1rem;
	height: 1rem;
	object-fit: cover;
}

.c-search__suggestion.is-highlighted .c-search__suggestion-image {
	box-shadow: 0 0 0 1px white;
}
