.c-viz__title-container {
	@apply flex;
	@apply items-start;
}

.c-viz__character-image-container {
	@apply bg-subtle-200;
	@apply mr-3;
	@apply rounded-full;
	@apply flex-shrink-0;
	width: 48px;
	height: 48px;
}

.c-viz__character-image {
	@apply block;
	@apply w-full;
	@apply h-full;
	@apply rounded-full;
	@apply shadow-md;
	object-fit: cover;
}

.c-viz__title {
	@apply text-2xl;
	@apply font-bold;
	@apply font-heading;
	@apply text-grey-900;
	@apply leading-tight;
}

.c-viz__kicker {
	@apply block;
	@apply text-sm;
	@apply font-normal;
	@apply font-body;
	@apply uppercase;
	@apply tracking-wider;
	@apply mb-1;
	@apply text-subtle-700;
}

.c-viz__header-search {
	@apply mt-4;
	@apply max-w-xs;
}

@screen md {
	.c-viz__header {
		@apply flex;
		@apply items-start;
	}

	.c-viz__title-container {
		@apply mr-auto;
	}

	.c-viz__header-search {
		@apply mt-0;
		@apply ml-4;
	}
}

.c-viz__scroll-container {
	@apply -mx-5;
}

.c-viz__chart-container {
	@apply mt-5;
	@apply pl-5;
	@apply flex;
	overflow-x: auto;
}

/* This is hacky, but it provides a spacer so that the
	 the chart is not flush with the screen when it overflows
	 and creates a scroll container. */
.c-viz__chart-container::after {
	@apply block;
	@apply w-5;
	@apply flex-none;
	content: '';
}
