.c-section {
	@apply p-5;
}

.c-section--text {
	@apply bg-white;
	@apply text-grey-900;
}

@screen md {
	.c-section {
		@apply py-10;
	}
}
