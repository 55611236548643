a,
.c-link {
	@apply text-emphasis-700;
	@apply border-b-2;
	@apply border-emphasis-700-a33;
	transition: 90ms border-color linear;
}

a:active,
a:focus,
a:hover,
.c-link:active,
.c-link:focus,
.c-link:hover {
	@apply border-emphasis-700;
}
