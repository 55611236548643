.o-container {
	@apply max-w-2xl;
	@apply mx-auto;
}

.o-container--wide {
	@apply max-w-6xl;
}

.o-container--extra-wide {
	max-width: 96rem;
}
